import React from 'react'
import { useNavigate } from "react-router-dom";
export default function BackButton(props) {
    let navigate = useNavigate();
    const routeChange = (path) => {
        navigate('../' + path, { replace: true });
    };
    return (
        <div>
             <div className='col-12'>
            <div className='row'>
                <div className="col-12" style={{ fontSize: '18px', marginTop: '10px' }} ><strong>Millionaire's Club</strong></div>
                <div className='col-12'>
                    
                    <div>
                        <center> <img src="https://cdn.ihsmarkit.com/www/images/0821/Company-178447404.jpg" style={{ width: '100%',marginBottom:20 }} alt /></center>
                    </div>
                <p style={{ fontSize: '0.26em', marginBottom: '10px', color: '#000' }}>
                Digital Miner was launched in 2023 and is one of the leading TRX cloud mining service providers. The purpose of this company is to maximize the interests of each user, so they can leverage large wealth with relatively low capital. “Invest once, enjoy forever,” that’s why all options are recognized and followed by Millionaire's Club. Through future data analysis, users should understand that Millionaire's Club pursues long-term strategic cooperative relationships with users, which is the reason they’ve grown to over 1 million members at this time. Launched in 2018, Millionaire's Club is a cloud mining service built on the Tron network. It provides users with a safe, convenient, and efficient TRX cloud mining experience. Users can visit the official Millionaire's Club website and register for an account with ease. The company enables users to mine the TRX cryptocurrency with a low-cost cloud service. Whether it’s quantitative trading or DeFi technology, Millionaire's Club makes it easy to participate in the blockchain revolution with its cloud mining services. To start cloud mining with Millionaire's Club, users can visit their website, register with an email, deposit TRX and the service will automatically start mining the TRX cryptocurrency for you.
                </p>
            
                </div>
                </div>
            </div>
        </div>
    )
}
