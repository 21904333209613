import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import Loading from './Loading';
import BackButton from './BackButton';
import NoRecord from './NoRecord';
export default function Order() {

    const [isLoading, setIsLoading] = useState(false);
    const [isFound, setisFound] = useState(false);
    const [orders, setOrders] = useState([]);
    let navigate = useNavigate();
    const routeChange = (path) => {
        navigate('../' + path, { replace: true });
    };
    useEffect(() => {
        MyOrders();
    }, []);
    const MyOrders = () => {
        setIsLoading(true);
        var param = new Object();
        param.ClientId = JSON.parse(localStorage.getItem("UserDetails").hexDecode()).ClientId;
        param.ActionMode = "GetOrders";
        var obj = new Object();
        obj.procName = 'AppReports';
        obj.Para = JSON.stringify(param);
        axios({
            method: 'POST',
            url: process.env.REACT_APP_WEBAPI_URL,
            data: obj
        }).then(function (res) {
            setIsLoading(false);
            res = res.data;
            setOrders(res);
            if (res != "NoRecord") {
                setisFound(true)
            } else {
                setisFound(false)
            }
        }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    }

    return (
        <div>
            <div className="navbar">
                <BackButton path='home' />
                <span style={{ marginRight: '25%',marginTop:'-15px',fontSize:17 }}>My Packages</span>
                <span />
            </div>
            {isLoading ? <Loading /> : ""}
            <div className="recharge-wrap" style={{minHeight:'151vh'}}>
                {/* <div className="text_div">
                    <ul style={{ listStyleType: 'circle' }}>
                        <li>Every midnight your ROI will be credited of all your puchases.</li>
                        <li>You can purchase multiple plans at the same time.</li>
                    </ul>
                </div> */}
                <div id="myOrders" style={{ marginTop: 0 }}>
                    {orders != "NoRecord" && orders.map((item, i) => {
                        const OrderList = (
                            <div className="order_div">

                                <div className="custom_card_plan">
                                    <div className="card_header"><small style={{ width: '100%', margin: 0, color: '#fff', fontSize: '18px!important',marginLeft: '1%' }} className="PackageName"><span style={{fontSize:11}}>Package No.#{(i+1)}</span> {item.PackageName} <span style={{ fontSize: 10 }}>{item.InvestmentDate}</span></small></div>
                                    <div className="grid_Outer">
                                        <div className="image_wrraper">
                                        {/* <img src='img/logo.png' className='productImg' style={{borderRadius:'5%'}} alt /> */}
                                        <img src={`img/${item.PackageImage}`} className='productImg' style={{borderRadius:'43%'}} alt />
                                        {/* <img src='https://assets.bwbx.io/images/users/iqjWHBFdfxIU/iMFwAwZ3QsVY/v0/-999x-999.gif' className='productImg' style={{borderRadius:'5%'}} alt /> */}
                                        </div>
                                        <div className="grid_2">
                                            <div className="card_content">
                                                <small>Package Price : <span className="TotalINVAmt">{item.Price} </span></small>
                                                <small>Package Profit : <span className="TotalReturnN">{item.TotalReturn} </span></small>
                                                <small>Daily Profit : <span className="DailyProfitN">{item.DailyProfit} </span></small>
                                                <small>Accumulated Profit: <span className="AlreayEarnedN">{item.AlreayEarned} </span></small>
                                                <p style={{ color: '#000', fontSize: 10 }}>(Daily Profit Credit time: {item.ROICreditTime})</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                        return OrderList;
                    })}

                </div>
                <div className="nodata nodata2" style={isFound ? { display: 'none' } : { display: 'block' }}>
                   
                <NoRecord />
                </div>
            </div>
            <div className="loading">
                <div>
                    <div className="loader" />
                    <p>loading...</p>
                </div>
            </div>
            <div className="msgbox" style={{ height: 'auto', zIndex: 9999 }}>
                <img src="img/msg2.png" alt />
                <p style={{ paddingBottom: 10 }} />
            </div>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                    .list-wrap ul li:nth-child(4n) {
                        margin-right: 10px;
                    }
                    // body {
                    //     background:black url(http://fc01.deviantart.net/fs71/f/2012/160/b/9/stars_by_paulinemoss-d52un4e.jpg);
                    //     animation: stars 250s linear alternate;
                    //   }
                    //   @keyframes stars {
                    //     0% { background-position:0 0 }
                    //     100% { background-position:0 100% }
                    //   }
                    .bgSelect img {
                        position: relative !important;
                        top: 4px !important;
                        height: 48px !important;
                    }
        
                    .mask-tips>div .text p {
                        text-align: center;
                        font-size: .24rem;
                        line-height: .39rem;
                        color: #ffa400;
                        font-weight: 400;
                        margin: 1px;
                    }
        
                    #SoonPopup {
                        position: fixed;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 40;
                        background: rgba(0, 0, 0, 0.4);
                        display: none;
                    }
        
                    #SoonPopup .mask-body {
                        background: #3a3333eb;
                        -webkit-box-shadow: 0px 0px 0.2rem rgb(0 0 0 / 16%);
                        box-shadow: 0px 0px 0.2rem rgb(0 0 0 / 16%);
                        border-radius: 0.2rem;
                        width: 6.9rem;
                        height: 4.96rem;
                        position: fixed;
                        left: 50%;
                        top: 50%;
                        -webkit-transform: translate(-50%, -50%);
                        transform: translate(-50%, -50%);
                    }
        
                    .list-wrap ul {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -ms-flex-wrap: wrap;
                        flex-wrap: wrap;
                        margin-top: 0.3rem;
                        padding-left: 0px;
                    }
        
                    .list-wrap ul li {
                        border-radius: 0.1rem;
                        text-align: center;
                        line-height: 21px;
                        font-size: .28rem;
                        font-weight: bold;
                        color: #5c5656;
                        margin-right: 0.22rem;
                        margin-bottom: 0.2rem;
                        list-style: none;
                        cursor: pointer;
                        padding: 3px 10px;
                        width: 1rem;
                        height: 78px;
                        border-left: 1px solid #ee0000;
                    }
        
                    .list-wrap ul {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -ms-flex-wrap: wrap;
                        flex-wrap: wrap;
                        margin-top: 0.3rem;
                        padding: 0px;
                        justify-content: center;
                    }
        
                    .list-wrap .btn {
                        height: 0.88rem;
                        line-height: .88rem;
                        background: #FBBD18;
                        border-radius: 0.44rem;
                        text-align: center;
                        color: #fff;
                        font-size: .36rem;
                        font-weight: 400;
                        margin: 0.6 rem 0 0.7rem 0;
                        cursor: pointer;
                        opacity: 1;
                        pointer-events: none;
                        width: 100%;
                    }
        
                    .selectmoney {
                        display: grid;
                        grid-template-columns: 4fr 4fr 4fr;
                        padding: 10px;
                    }
        
                    .selectdata {
                        display: flex;
                        background: #ffffff;
                        padding: 0px 0px;
                        border-radius: 8px;
                        margin: 4px;
                        justify-content: center;
                        position: relative;
                        border: 1px solid #998e8e;
                    }
        
                    .selectdataimg img {
                        filter: invert(1) !important;
                        height: 15px !important;
                    }
        
                    .selecttext p {
                        margin: 0px;
                        font-size: 18px;
                        color: #998e8e !important;
                    }
        
                    .selectdata.active {
                        background-color: #ffffff;
                    }
        
                    .selectdata.active:after {
                        content: "\f058";
                        position: absolute;
                        left: 76px;
                        font-size: 21px;
                        color: #f23e36;
                        font-family: 'FontAwesome';
                    }
        
                    .list-wrap .hwrap input {
                        height: 100%;
                        width: 100%;
                        height: 48px;
                        border: none;
                        background: transparent;
                        font-size: .32rem;
                        font-weight: 500;
                        text-align: center;
                        color: #333;
                    }
        
                    .list-wrap .hwrap {
                        width: 6.98rem;
                        height: 49px;
                        background: #F6F6F6;
                        border-radius: 0.2rem;
                        margin-top: 13px;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        margin-bottom: 0.4rem;
                    }
        
                    .save-btn {
                        background: #FBBD18;
                        color: #fff;
                        margin: 12px;
                        height: 40px;
                        border-radius: 50px;
                        text-align: center;
                        line-height: 40px;
                        margin-top: -14px;
                        width: 90%;
                        font-size: 20px;
                        font-family: Microsoft YaHei !important;
                    }
        
                    .list-wrap .tips {
                        font-size: 12px;
                        font-weight: 500;
                        line-height: .45rem;
                        color: #999999;
                        margin-top: 0.1rem;
                        color: purple;
                    }
        
                    .recharge-wrap .list-wrap .custom_color {
                        justify-content: space-between;
                        background: #015990;
                        border-radius: 0.2rem;
                        box-shadow: 0px 4px 12px #001437;
                        margin-bottom: 10px;
                    }
        
                    .recharge-wrap .list-wrap ul .li-width {
                        width: 40%;
                        background: transparent;
                        border: none;
                        color: #fff;
                    }
        
                    .pay_btn {
                        width: 32%;
                        background: #006c96;
                        font-weight: 400;
                        line-height: .88rem;
                        color: #FFFFFF;
                        cursor: pointer;
                        text-align: center;
                        list-style: none;
                        border: none;
                        border-radius: 50px !important;
                        display: inline-block;
                        font-size: 16px;
                        margin: 7px 2px;
                        text-decoration: none;
                        position: relative
                    }
        
                    .hot {
                        font-size: 13px;
                        color: #fff;
                        background: red;
                        height: 22px;
                        width: 30px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 15px 15px 0px 15px;
                        position: absolute;
                        right: 3px;
                        top: -10px;
                    }
        
                    .text_div {
                        background: rgba(252,67,67,.1);
                        border-radius: 10px;
                        margin-top: 20px;
                        color: rgb(0, 102, 204);
                        padding: 10px;
                    }
        
                    span.inr {
                        font-weight: 100;
                        display: block;
                        color: #000;
                        font-size: .28rem !important;
                    }
        
                    .recharge-wrap {
                        margin-top: 15px;
                        padding: 15px;
                    }
        
                    .text_div ul {
                        margin: 0;
                        padding-left: 15px;
                    }
        
                    .text_div ul li {
                        font-size: 14px;
                    }
        
                    .image_wrraper {
                        height: 80px;
                        width: 109px;
                        margin: 0 auto;
                        position: absolute;
                        right: 30px;
                    }
        
                    .image_wrraper img {
                        height: 134%;
                        width: 112%;
                    }
        
                    .custom_card_plan {
                        background: none !important;
                        box-shadow: 2px 3px 10px #bab8b8;
                        min-height: 150px;
                        border-radius: 20px;
                        margin-top: 5px;
                        position: relative;
                        // border-bottom: 1px dashed red;
                    }
        
                    .custom_card_plan .card_title {
                        text-align: center;
                        color: #fff;
                        font-size: 17px;
                        margin: 0 0 5px;
                    }
        
                    .custom_card_plan p {
                        color: #fff;
                        font-size: 13px;
                        margin: 5px 0;
                    }
        
                    small {
                        font-size: 13px;
                        color: #000;
                        display: block;
                        font-weight: 600;
                        width: 60%;
                        margin: 5px 0;
                    }
        
                    small span {
                        font-size: 15px;
                    }
        
                    .card_header {
                        background: linear-gradient(270deg, #ffc107, rgb(40, 167, 69)) !important;
                        padding: 15px;
                        border-radius: 20px;
                        color:#fff;
                    }
        
                    .custom_card_plan .grid_Outer {
                        padding: 15px;
                        min-height: 100px;
                        border-radius: 2px;
                    }
                    .buy-btn {
                    width: 4.4rem;
                    height: 29px;
                    border: none;
                    background: #FBBD18;
                    border-radius: 2px;
                    text-align: center;
                    line-height: normal;
                    font-size: 14px;
                    font-weight: normal;
                    color: #000;
                    margin-bottom: .4rem;
                    border-radius:25px;
                }
                .nodata img {
                    width: 170px!important;
                    height:157px!important;
                    display: block;
                    margin: 0 auto;
                }
          `
                }}
            />
        </div>

    )
}
