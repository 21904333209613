import React from 'react'
import QRCode from 'react-qr-code';
import { useState, useEffect } from 'react';
import axios from "axios";
import Loading from './Loading';
import toast, { Toaster } from 'react-hot-toast';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import BackButton from './BackButton';

export default function Deposit() {
    const [value, setValue] = useState("");
    const [userName, setUserName] = useState("");
    const [back, setBack] = useState('#FFFFFF');
    const [fore, setFore] = useState('#000000');
    const [size, setSize] = useState(200);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        GetDEpositAddress();
    }, []);
    const GetDEpositAddress = () => {
        setIsLoading(true);
        var param = new Object();
        param.ClientId = JSON.parse(localStorage.getItem("UserDetails").hexDecode()).ClientId;
        axios({
            method: 'POST',
            url: process.env.REACT_APP_API_URL + "GenerateTRC20Address",
            data: param
        }).then(function (res) {
            setIsLoading(false);
            res = res.data;
            setValue(res[0].Msg);
        }).catch(function (error) {
            setIsLoading(false);
        });
    }
    const CopyCallBack = (t, r) => {
        if (r == true) {
            toast.success("Copied");
        }
    }
    return (
        <>
            <div><Toaster toastOptions={{
                // Define default options
                position: "top-center",
                className: '',
                duration: 5000,
                style: {
                    fontSize: '0.28em',
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff'
                }
            }} /></div>
            {isLoading ? <Loading /> : ""}
            <div className="recharge-wrap recharge-wrap2" style={{ minHeight: '110vh' }}>
                <div className="navbar navbar2">
                    <BackButton path='home' />
                    <span style={{ marginLeft: 82 }}>Recharge</span>
                    <span />
                </div>
                <div className=" list-wrap3" style={{ margin: 5 }}>
                    <div className="billdeailsnew">
                        <div className="billlist">
                            <div className="billddiscription" style={{ boxShadow: '2px 3px 10px #bab8b8', borderRadius: 0, margin: '55px 15px', background: '#fff' }}>
                                <div className="invoce_header" style={{ background: '#eee', color: '#fff' }}>
                                    <p style={{ margin: 0, fontSize: 17, color: '#000', padding: '0px 0', textAlign: 'center' }}>
                                        Network: TRC 20
                                    </p>

                                    <p className='blink_me' id="refUsername" style={{ margin: 0, fontSize: 17, color: '#ff6600', padding: '0px 0 15PX', textAlign: 'center', fontWeight: 600 }}>
                                        &nbsp;
                                    </p>
                                </div>
                                <div className style={{ textAlign: 'center', padding: '10px 15px 25px' }}>
                                    <p style={{ margin: 0, fontSize: 17, color: '#000', padding: '15px 0', textAlign: 'center' }}>
                                        Scan QR Code
                                    </p>

                                    <QRCode
                                        title="GeeksForGeeks"
                                        value={value}
                                        bgColor={back}
                                        fgColor={fore}
                                        size={size === '' ? 0 : size}
                                    />
                                    <input value={value} type="text" style={{ width: '80%', borderRadius: '0.133333rem', fontSize: 12, border: 'none', marginTop: 5, marginBottom: 10, marginLeft: 0, height: '0.8rem', background: 'rgb(252 118 67 / 30%)', paddingLeft: '0.4rem', fontWeight: 700, color: '#000!important' }} id="refLink" />

                                    <CopyToClipboard text={value} onCopy={CopyCallBack}>
                                        <button className="copy_btn" style={{ border: 'none' }}>Copy Address</button>
                                    </CopyToClipboard>
                                    <p style={{ margin: 0, fontSize: 13, color: '#000', padding: '15px 0', textAlign: 'center' }}>Please only send USDT to above address, Sending any other asset to this address may result in the loss of your deposit!</p>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                    .optionnew {
                        padding: 10px;
                        border-bottom: 1px solid #eee;
                    }
                    .blink_me {
                        animation: blinker 1s linear infinite;
                      }
                      
                      @keyframes blinker {
                        50% {
                          opacity: 0;
                        }
                      }
                    .copy_btn {
                        text-decoration: none;
                        font-size: 16px;
                        background: #F76300 !important;
                        padding: 7px 0px;
                        border-radius: 50px;
                        color: #fff;
                        display: block;
                        margin: 0 auto;
                        width: 70%;
                    }
          `
                }}
            />
        </>
    )
}
