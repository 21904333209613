import React from 'react'
import NoRecord from './NoRecord';
export default function PaymentOptions(props) {
    return (
        <>
            {props.data != null ? props.data.map((item, i) => {
                const PackageList = (
                    <label class="radio-inline" style={{ marginLeft: 0,padding:7,width:'100%',background:'linear-gradient(179.4deg, rgb(12, 20, 69) -16.9%, rgb(71, 30, 84) 119.9%)',borderRadius:5 }} onClick={() => props.func(item.CoinType,item.imageType)} key={i}>
                        
                        <input type="radio" name="optpay"/><span className='radioLabel' style={{color:'#fff'}}> <img src={`img/${item.imageType}`} style={{height:40}}></img>  {item.CoinName}&nbsp;<i style={{color:'#fff',fontSize:12}}>(Min Deposit: {item.minDeposit} {item.CoinName})</i></span>
                       
                    </label>
                );
                return PackageList;
            }) : <NoRecord />}
        </>
    )
}
