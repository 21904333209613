import React from 'react'
import Tabs from './Tabs';
import toast, { Toaster } from 'react-hot-toast';
import BackButton from './BackButton';
export default function Plans() {
    return (
        <>
            <div><Toaster toastOptions={{
                // Define default options
                position: "top-center",
                className: '',
                duration: 5000,
                style: {
                    fontSize: '0.28em',
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff'
                }
            }} /></div>
            <div className="recharge-wrap recharge-wrap2" style={{ minHeight: '125vh' }}>
                <div className="navbar">
                    <BackButton path='home' />
                    <span style={{ marginRight: '25%', marginTop: '-15px' }}>Our Packages</span>

                </div>
                <Tabs />
            </div>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                    .nav-tabs{
                        display:none!important;
                    }
                    
          `
                }}
            />
        </>
    )
}
