import React, { useRef, useState, useEffect } from 'react'
import { Helmet } from "react-helmet";
import BackButton from './BackButton';
import axios from "axios";
import Loading from './Loading';
import toast, { Toaster } from 'react-hot-toast';

export default function BankCard() {

    const inputEls = useRef([]);
    const [upicount, setupicount] = useState(0);
    //Handling Loading 
    const [isLoading, setIsLoading] = useState(false);
    const [upiname, setupiname] = useState("");
    const [verifiedstatus, setVerifiedstatus] = useState(0);
    //State Management
    const [allValues, setAllValues] = useState({
        BeneficiaryName: '',
        MobileNo: '',
        AccountNo: '',
        UPI: '',
        IFSCCode: '',
        Password: '',
        actionMode: 'AddBeneficiary'
    });
    const changeHandler = e => {
        setAllValues({ ...allValues, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        GetBeneficiary();
    }, []);
    const GetBeneficiary = () => {
        setIsLoading(true);
        var param = new Object();
        param.MemberId = JSON.parse(localStorage.getItem("UserDetails").hexDecode()).ClientId;
        param.ActionMode = "SearchBeneficiary";
        var obj = new Object();
        obj.procName = 'SenderRegistration';
        obj.Para = JSON.stringify(param);
        axios({
            method: 'POST',
            url: process.env.REACT_APP_WEBAPI_URL,
            data: obj
        }).then(function (res) {
            setIsLoading(false);
            res = res.data;
            if (res != "") {
                if (res != "NoRecord") {
                    setAllValues({
                        actionMode: "UpdateBeneficiary", BeneficiaryName: res[0].FirstName, MobileNo: "",
                        AccountNo: res[0].AccountNo, UPI: res[0].UPI, IFSCCode: res[0].IFSC, Password: ""
                    })
                } else {
                    setAllValues({
                        actionMode: "AddBeneficiary", BeneficiaryName: "", MobileNo: "",
                        AccountNo: "", UPI: "", IFSCCode: "", Password: ""
                    })
                }
            }
        }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    }

    const addbeneficiary = () => {
        if (allValues.BeneficiaryName == "") {
            toast.error('Enter Full Name.');
            inputEls.current[1].focus();
            return;
        }
        if (allValues.MobileNo.toString().length < 10 || allValues.MobileNo.toString().length > 10) {
            toast.error('Enter Valid Mobile No.');
            inputEls.current[2].focus();
            return;
        }
        if (allValues.UPI == "") {
            toast.error('Enter UPI ID.');
            inputEls.current[6].focus();
            return;
        }
        if (/^[\w.-]+@[\w.-]+$/.test(allValues.UPI) == false) {
            toast.error('Invalid UPI ID.');
            inputEls.current[6].focus();
            return;
        }
        if (allValues.AccountNo == "") {
            toast.error('Enter Account No.');
            inputEls.current[3].focus();
            return;
        }
        if (allValues.IFSCCode == "") {
            toast.error('Enter IFSC Code.');
            inputEls.current[4].focus();
            return;
        }
        if (allValues.Password == "") {
            toast.error('Enter Withdraw Password.');
            inputEls.current[5].focus();
            return;
        }
        setIsLoading(true);
        var regObj = new Object();
        regObj.MobileNo = allValues.MobileNo;
        regObj.FirstName = allValues.BeneficiaryName;
        regObj.Password = allValues.Password;
        regObj.UPI = allValues.UPI;
        regObj.AccountNo = allValues.AccountNo;
        regObj.IFSC = allValues.IFSCCode;
        regObj.MemberId = JSON.parse(localStorage.getItem("UserDetails").hexDecode()).ClientId;
        regObj.ActionMode = allValues.actionMode;
        var obj = new Object();
        obj.procName = 'SenderRegistration';
        obj.Para = JSON.stringify(regObj);
        axios({
            method: 'POST',
            url: process.env.REACT_APP_WEBAPI_URL,
            data: obj
        }).then(function (res) {
            setIsLoading(false);
            res = res.data;
            if (res[0].StatusCode == "1") {
                toast.success(res[0].Msg);
            }
            else {
                toast.error(res[0].Msg);
            }
        }).catch(function (error) {
            setIsLoading(false);
        });
    }

    const ValidateUPI = () => {
        
        if (parseInt(localStorage.getItem("validationCount")) >= 5) {
            toast.error('You can validate UPI ID only 5 times.');
            return;
        }
        if (allValues.UPI == "") {
            toast.error('Enter UPI ID.');
            inputEls.current[6].focus();
            return;
        }
        if (/^[\w.-]+@[\w.-]+$/.test(allValues.UPI) == false) {
            toast.error('Invalid UPI ID.');
            inputEls.current[6].focus();
            return;
        }
        setIsLoading(true);
        var param = new Object();
        param.UPI = allValues.UPI;
        axios({
            method: 'POST',
            url: process.env.REACT_APP_API_URL + "ValidateUPI",
            data: param
        }).then(function (res) {
            setIsLoading(false);
            res = res.data;
            res = JSON.parse(res);
            if (res.data.success == true) {
                setupiname(res.data.payeeName);
                setVerifiedstatus(1);
                setupicount(upicount + 1);
                localStorage.setItem("validationCount", upicount)
            }else{
                toast.error('Invalid UPI ID.');
            }

        }).catch(function (error) {
            setIsLoading(false);
        });
    }
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bank Card</title>
                <link href="css/Recharge.css" rel="stylesheet" />
            </Helmet>
            <div><Toaster toastOptions={{
                // Define default options
                position: "top-center",
                className: '',
                duration: 5000,
                style: {
                    fontSize: '0.28em',
                    borderRadius: '10px',
                    background: 'red',
                    color: '#fff'
                }
            }} /></div>
            {isLoading ? <Loading /> : ""}
            <div class="recharge-wrap recharge-wrap2">
                <div class="navbar navbar2">
                    <BackButton path='home' />
                    <span style={{ marginRight: '25%', marginTop: '-15px' }}>Bank Card</span>
                    <span>
                    </span>
                </div>
                <div className="list-wrap list-wrap3">
                    <ul className="uls" style={{ padding: 0 }}>
                        <li>
                            <span>Name On Card</span>
                            <input value={allValues.BeneficiaryName} name="BeneficiaryName" id="BeneficiaryName" ref={(el) => (inputEls.current[1] = el)} onChange={changeHandler} maxLength={250} class="form-control" placeholder="Full Name" />
                        </li>
                        <li>
                            <span>Mobile No</span>
                            <input name="MobileNo" id="MobileNo" type="number" ref={(el) => (inputEls.current[2] = el)} onChange={changeHandler} maxLength={10} class="form-control" placeholder="Phone number" />
                        </li>
                        <li>
                            <span>UPI ID</span>

                            <input name="UPI" value={allValues.UPI} id="UPI" ref={(el) => (inputEls.current[6] = el)} onChange={changeHandler} class="form-control" placeholder="UPI ID" style={{ width: '60%' }} />
                            <button className='validate' onClick={ValidateUPI}>
                                Validate UPI
                            </button>

                        </li>
                        {verifiedstatus == 1 ? <span className='upiname'>{upiname}<img style={{ height: 18 }} src='https://cdn3.emoji.gg/emojis/1134-verified-animated.gif'></img></span> : null}

                        <li>

                            <span>Account Number</span>
                            <input name="AccountNo" type="number" value={allValues.AccountNo} id="AccountNo" ref={(el) => (inputEls.current[3] = el)} onChange={changeHandler} maxLength={20} runat="server" class="form-control number" placeholder=" Bank Account" />
                        </li>
                        <li>
                            <span>IFSC Code</span>
                            <input name="IFSCCode" value={allValues.IFSCCode} id="IFSCCode" ref={(el) => (inputEls.current[4] = el)} onChange={changeHandler} maxLength={18} class="form-control" placeholder="IFSC" />
                        </li>
                        <li>
                            <span>Login Password</span>
                            <input name="Password" id="Password" ref={(el) => (inputEls.current[5] = el)} onChange={changeHandler} type="password" class="form-control" placeholder="Login password" />
                        </li>
                    </ul>
                   
                    <button style={{ marginTop:-25}} runat="server" id="Senderreg" class="btn btn-primary sub button1" onClick={addbeneficiary}>
                        Confirm
                    </button>
                    <p className="tips-red" style={{ marginTop:-25,fontStyle: 'italic', color: '#2CA64B' }}>
                        Please mention the correct UPI & bank account details to avail smooth withdrawal otherwise company would not be responsible for any fund loss!
                    </p>
                    </div>
            </div>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                    .upiname{
                        color: green;
                        font-size: 18px;
                        margin-top: -27px;
                        position: absolute;
                    }
                    .validate{
                        width: 32%;
                        position: absolute;
                        margin-top: -39px;
                        font-size: 15px!important;
                        padding: 5px!important;
                        margin-left: 14%;
                        background-color: green;
                        color:#fff;
                        height:36px;
                        line-height:1px;
                        border-radius:5px;
                    }
                    .list-wrap.list-wrap3 .uls {
                        display: block;
                        padding-top: 19px !important;
                    }
                    .recharge-wrap2{
                        padding-top:0px!important;
                    }
                    .form-control {
                        font-size: 15px !important;
                        display: block;
                        padding: 0 0rem;
                        width: 100%;
                        height: calc(1.5em + 0.75rem + 2px);
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #495057;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                    }
                    .list-wrap.list-wrap3 .uls li {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: block;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        height: 1.02rem;
                        border-bottom: 1px solid #f5f5f5;
                        background: transparent;
                        width: 100%;
                        margin-bottom: 17px;
                    }
        
                    .list-wrap.list-wrap3 .uls li span {
                        font-size: .28rem;
                        font-weight: 400;
                        color: #262626;
                        width: 2.5rem;
                        text-align: left;
                        display: none;
                    }
        
                    .list-wrap.list-wrap3 .uls li input {
                        -webkit-box-flex: 1;
                        -ms-flex: 1;
                        flex: 1;
                        height: 80%;
                        border: none;
                        font-size: 14px;
                        color: #333;
                        font-weight: 400;
                        background: #eee;
                        width: 100%;
                        padding: 6px 4px;
                        border-radius: 8px;
                    }
        
                    .button1 {
                        background-color: #4A54C9;
                        /* Green */
                        border: none;
                        color: white;
                        padding: 10px 32px;
                        text-align: center;
                        text-decoration: none;
                        display: inline-block;
                        font-size: 16px;
                        width: 100%;
                        font-size: .36rem;
                        font-weight: 400;
                        opacity: 0.5;
                        border-radius: 20px;
                        margin: 0.6rem 0 0.7rem 0;
                    }
        
                    .list-wrap.list-wrap3 .uls li span {
                        font-size: .28rem;
                        font-weight: 400;
                        color: #262626;
                        width: 2.5rem;
                        text-align: left;
                    }
        
                    input:focus {
                        outline: none !important;
                        border-color: #719ECE;
        
                    }
        
                    .list-wrap .btn {
                        height: 0.88rem;
                        line-height: 0px;
                        background: #000;
                        border-radius: 0.44rem;
                        text-align: center;
                        color: #fff;
                        font-size: .36rem;
                        font-weight: 400;
                        margin: 0.6rem 0 0.7rem 0;
                        cursor: pointer;
                        opacity: 1;
                        pointer-events: all;
                        width: 100%;
                        border-radius: 4px;
                    }
          `
                }}
            />
        </>
    )
}
