import React from 'react'
import { useNavigate } from "react-router-dom";
export default function Footer() {
  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate('../' + path, { replace: true });
  };
  return (
    <>
      <div className="tab-bar">
        <div className="active" onClick={() => routeChange('home')}>
          <i />
          <p>Home</p>
        </div>
        <div onClick={() => routeChange('invite')}>
          <i />
          <p>Invite</p>
        </div>
        <div onClick={() => routeChange('invest')}>
          <i className='box' style={{ height: 50, width: 50, marginTop: -9 }} />
          <p>Mining Plans</p>
        </div>
        <div onClick={() => routeChange('team')}>
          <i />
          <p>Team</p>
        </div>
        <div onClick={() => routeChange('UserProfile')}>
          <i />
          <p>Me</p>
        </div>
      </div>

    </>
  )
}
